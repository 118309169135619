import React from 'react'
import { BrowserRouter as Router, Route, Routes, Navigate } from 'react-router-dom'
import { ThemeProvider } from './contexts/ThemeContext'
import { AuthProvider } from './contexts/AuthContext'
import PrivateRoute from './components/PrivateRoute'
import PublicRoute from './components/PublicRoute'
import Navbar from './components/Navbar'
import DealerList from './components/DealerList'

import ErrorBoundary from './components/ErrorBoundary'
import AdminLogin from './components/AdminLogin'
import DealersManagement from './components/DealersManagement'
import DealerDetails from './components/DealerDetails'
import DealerRegistration from './components/DealerRegistration'
import AssociateRegistration from './components/AssociateRegistration'
import AssociatesManagement from './components/AssociatesManagement'
import AssociateReports from './components/AssociateReports'







function App() {
  return (
    <Router>
      <ThemeProvider>
        <AuthProvider>
          <ErrorBoundary>
            <div className="min-h-screen bg-gradient-to-br from-lime-50 to-green-100 dark:from-gray-900 dark:to-gray-800 transition-colors duration-300">
              <Navbar />
              <main className="max-w-7xl mx-auto py-6 sm:px-6 lg:px-8">
                <Routes>
                  <Route path="/dealer-list" element={<DealerList />} />
                  <Route path="/login" element={<PublicRoute><AdminLogin /></PublicRoute>} />
                  <Route path="/dashboard" element={<PrivateRoute allowedRoles={['admin']}><DealersManagement /></PrivateRoute>} />
                  <Route path="/dealers" element={<PrivateRoute allowedRoles={['admin']}><DealersManagement /></PrivateRoute>} />
                  <Route path="/associates" element={<PrivateRoute allowedRoles={['admin']}><AssociatesManagement /></PrivateRoute>} />
                  <Route path="/dealers/:id" element={<PrivateRoute allowedRoles={['admin', 'dealer']}><DealerDetails /></PrivateRoute>} />
                  <Route path="/dealers/new" element={<PrivateRoute allowedRoles={['admin']}><DealerRegistration /></PrivateRoute>} />
                  <Route path="/associates/new/" element={<PrivateRoute allowedRoles={['admin', 'dealer']}><AssociateRegistration /></PrivateRoute>} />
                  <Route path="/associates/edit/:id" element={<PrivateRoute allowedRoles={['admin']}><AssociateRegistration isEdit={true} /></PrivateRoute>} />
                  <Route path="/dealers/:id/add-associate" element={<PrivateRoute allowedRoles={['admin', 'dealer']}><AssociateRegistration /></PrivateRoute>} />
                  <Route path="/" element={<Navigate to="/dashboard" replace />} />
                  <Route path="/associates/:id/reports" element={<PrivateRoute allowedRoles={['admin', 'dealer']}><AssociateReports /></PrivateRoute>} />
                </Routes>
              </main>
            </div>
          </ErrorBoundary>
        </AuthProvider>
      </ThemeProvider>
    </Router>
  )
}

export default App



