import React, { useState, useEffect } from 'react'
import { motion, AnimatePresence } from 'framer-motion'
import { Search, Phone, WandSparkles, Clock, Download, Filter, ChevronDown, ChevronUp, User, MapPin, Mail, Calendar, FileText } from 'lucide-react'
import axios from 'axios'
import axiosApi from '../api/axiosApi'
import { useParams } from 'react-router-dom'
import jsPDF from 'jspdf'
import 'jspdf-autotable'
import NoReportsFound from './NoReportsFound'
import Loader from './Loader'  // Import the new Loader component

export default function AssociateReports() {
    const [associateData, setAssociateData] = useState(null)
    const [reports, setReports] = useState([])
    // param id
    const { id } = useParams()
    const [loading, setLoading] = useState(true)
    const [error, setError] = useState(null)
    const [vehicleNumber, setVehicleNumber] = useState('')
    const [mobileNumber, setMobileNumber] = useState('')
    const [showFilters, setShowFilters] = useState(false)
    const [dateFrom, setDateFrom] = useState('')
    const [dateTo, setDateTo] = useState('')
    const [status, setStatus] = useState('')
    const [search, setSearch] = useState('')
    const [isGeneratingPDF, setIsGeneratingPDF] = useState(false)  // New state for PDF generation

    useEffect(() => {
        fetchAssociateDetails()
        fetchAssociateReports()
    }, [id])

    const fetchAssociateDetails = async () => {
        try {
            const response = await axiosApi.post(`/getAssociateDetails?associateId=${id}`)
            setAssociateData(response.data.data)
            setLoading(false)
        } catch (err) {
            setError('Failed to fetch associate details')
            setLoading(false)
        }
    }

    const fetchAssociateReports = async () => {
        try {
            const response = await axiosApi.get(`/report/fetch?associateId=${id}`)
            setReports(response.data.data || [])
            setLoading(false)
        } catch (err) {
            setError('Failed to fetch associate reports')
            setLoading(false)
        }
    }

    const filteredReports = reports.filter(report => {
        const searchLower = search.toLowerCase();
        const vehicleNumberLower = vehicleNumber.toLowerCase();
        const dateCreated = new Date(report.createdAt).toISOString().split('T')[0];

        return (
            (report.client_name.toLowerCase().includes(searchLower) ||
                report.vehicle_number.toLowerCase().includes(searchLower)) &&
            (vehicleNumberLower === '' || report.vehicle_number.toLowerCase().includes(vehicleNumberLower)) &&
            (status === '' || report.status.toLowerCase() === status.toLowerCase()) &&
            (dateFrom === '' || dateCreated >= dateFrom) &&
            (dateTo === '' || dateCreated <= dateTo)
        );
    });

    const generatePDF = async (report) => {
        setIsGeneratingPDF(true)  // Start loading
        const doc = new jsPDF();
        const pageWidth = doc.internal.pageSize.width;

        // Helper function to add a section
        const addSection = (title, startY) => {
            doc.setFontSize(16);
            doc.setTextColor(46, 125, 50); // Dark green color
            doc.text(title, 10, startY);
            return startY + 10;
        };

        // Add logo
        doc.addImage('/logo.png', 'PNG', 10, 10, 80, 20);

        // Header
        doc.setFontSize(20);
        doc.setTextColor(46, 125, 50);
        doc.text('Detox Analysis Report', pageWidth - 10, 20, { align: 'right' });

        doc.setFontSize(12);
        doc.setTextColor(0);
        doc.text(`Report #${report.id}`, pageWidth - 10, 30, { align: 'right' });
        doc.text(`Status: ${report.status}`, pageWidth - 10, 35, { align: 'right' });
        doc.text(`Generated by: ${report.report_generated_by_name ?? report.report_generated_by}`, pageWidth - 10, 40, { align: 'right' });
        doc.text(`Date: ${new Date(report.createdAt).toLocaleString()}`, pageWidth - 10, 45, { align: 'right' });

        // Vehicle Details
        let yPos = addSection('Vehicle Details', 60);

        const vehicleDetails = [
            ['Client Name', report.client_name],
            ['Vehicle Number', report.vehicle_number],
            ['Vehicle Reading', report.vehical_reading],
            ['Vehicle Type', report.vehical_type],
            ['Vehicle Company', report.vehicle_company_name],
            ['Fuel Type', report.vehicle_fuel],
        ];

        doc.autoTable({
            startY: yPos,
            head: [['Parameter', 'Value']],
            body: vehicleDetails,
            theme: 'grid',
            headStyles: { fillColor: [232, 245, 233], textColor: [46, 125, 50] },
        });

        // Parse inspection reports
        const preInspection = JSON.parse(report.pre_inspection_report || '{}');
        const postInspection = JSON.parse(report.inspection_report || '{}');

        // Scan Report
        yPos = addSection('Scan Report', doc.lastAutoTable.finalY + 15);

        const scanReport = [
            ['Engine Load', preInspection.engine_load_before || 'N/A', postInspection.engine_load_after || 'N/A'],
            ['EGR Reading', preInspection.egr_reading_before || 'N/A', postInspection.egr_reading_after || 'N/A'],
            ['Mass Air Flow', preInspection.mass_air_flow_before || 'N/A', postInspection.mass_air_flow_after || 'N/A'],
            ['Air Fuel Mixture', preInspection.air_fuel_mixture_before || 'N/A', postInspection.air_fuel_mixture_after || 'N/A'],
            ['HP', preInspection.hp_before || 'N/A', postInspection.hp_after || 'N/A'],
            ['Throttle Position', preInspection.throttle_position_before || 'N/A', postInspection.throttle_position_after || 'N/A'],
        ];

        doc.autoTable({
            startY: yPos,
            head: [['Findings', 'Before', 'After']],
            body: scanReport,
            theme: 'grid',
            headStyles: { fillColor: [232, 245, 233], textColor: [46, 125, 50] },
        });

        // Other Findings
        yPos = addSection('Other Findings', doc.lastAutoTable.finalY + 15);

        const otherFindings = [
            ['Alternator Output', preInspection.alternator_reading_before || 'N/A', postInspection.alternator_reading_after || 'N/A'],
            ['Thermostat Reading', preInspection.thermostat_reading_before || 'N/A', postInspection.thermostat_reading_after || 'N/A'],
            ['Fault Codes', preInspection.fault_codes_before || 'N/A', postInspection.fault_codes_after || 'N/A'],
        ];

        doc.autoTable({
            startY: yPos,
            head: [['Parameters', 'Before', 'After']],
            body: otherFindings,
            theme: 'grid',
            headStyles: { fillColor: [232, 245, 233], textColor: [46, 125, 50] },
        });

        // Pollution Findings
        yPos = addSection('Pollution Findings', doc.lastAutoTable.finalY + 15);

        const pollutionFindings = [
            ['HSU', preInspection.hsu_before || 'N/A', postInspection.hsu_after || 'N/A'],
            ['LAMBDA', preInspection.lambda_before || 'N/A', postInspection.lambda_after || 'N/A'],
            ['CO', preInspection.co_before || 'N/A', postInspection.co_after || 'N/A'],
            ['CO2', preInspection.co2_before || 'N/A', postInspection.co2_after || 'N/A'],
            ['NOX', preInspection.nox_before || 'N/A', postInspection.nox_after || 'N/A'],
            ['O2', preInspection.o2_before || 'N/A', postInspection.o2_after || 'N/A'],
        ];

        doc.autoTable({
            startY: yPos,
            head: [['Parameters', 'Before', 'After']],
            body: pollutionFindings,
            theme: 'grid',
            headStyles: { fillColor: [232, 245, 233], textColor: [46, 125, 50] },
        });

        // Mechanical Findings
        yPos = addSection('Mechanical Findings', doc.lastAutoTable.finalY + 15);

        const mechanicalFindings = [
            ['Engine Back Compression', preInspection.engineBackCompression || 'N/A'],
            ['Black Smoke', preInspection.blackSmoke || 'N/A'],
            ['White/Blue Smoke', preInspection.whiteBlueSmoke || 'N/A'],
            ['Air Filter Condition', preInspection.airFilterCondition || 'N/A'],
            ['Engine Oil', preInspection.engineOil || 'N/A'],
            ['Break Oil', preInspection.breakOil || 'N/A'],
            ['Steering Oil', preInspection.steeringOil || 'N/A'],
            ['MAF Sensor Clean', preInspection.mafSensorClean || 'N/A'],
        ];

        doc.autoTable({
            startY: yPos,
            head: [['Parameter', 'Status']],
            body: mechanicalFindings,
            theme: 'grid',
            headStyles: { fillColor: [232, 245, 233], textColor: [46, 125, 50] },
        });

        // Expert Findings
        yPos = addSection('Expert Findings', doc.lastAutoTable.finalY + 15);

        const expertFindings = [
            ['Pickup Improve', postInspection.pickup_improve || 'N/A'],
            ['Smoothness Improve', postInspection.smoothness_improve || 'N/A'],
            ['Black Smoke Reduce', postInspection.black_smoke_reduce || 'N/A'],
            ['Vibration Improve', postInspection.vibration_improve || 'N/A'],
            ['Engine Noise Improve', postInspection.engine_noise_improve || 'N/A'],
            ['Acceleration Improve', postInspection.acceleration_improve || 'N/A'],
            ['Clutch Improve', postInspection.clutch_improve || 'N/A'],
            ['Knocking Improve', postInspection.knocking_improve || 'N/A'],
            ['Gear Shifting', postInspection.gear_shifting || 'N/A'],
            ['Breaks Working', postInspection.breaks_working || 'N/A'],
            ['Temperature', postInspection.temperature || 'N/A'],
        ];

        doc.autoTable({
            startY: yPos,
            head: [['Parameter', 'Status']],
            body: expertFindings,
            theme: 'grid',
            headStyles: { fillColor: [232, 245, 233], textColor: [46, 125, 50] },
        });

        // Save the PDF
        doc.save(`Report_${report.id}.pdf`);
        setIsGeneratingPDF(false)
    };

    const handleRefresh = () => {
        setLoading(true);
        fetchAssociateReports();
    };

    if (loading) return <Loader />
    if (error) return <div className="text-center text-red-500 text-xl mt-8">Error: {error}</div>
    if (!associateData) return <Loader />

    return (
        <motion.div
            className="bg-white shadow-xl rounded-lg overflow-hidden"
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.5 }}
        >
            <div className="px-6 py-4 bg-gradient-to-r from-lime-600 to-green-600 text-white">
                <h3 className="text-2xl font-bold">Associate Reports</h3>
            </div>
            <div className="p-6">
                {/* Associate Details Section */}
                <div className="mb-8 bg-gray-50 rounded-lg p-6 shadow-md">
                    <div className="flex items-center mb-4">
                        {associateData.image ? (
                            <img
                                src={associateData.image}
                                alt={associateData.name}
                                className="w-20 h-20 rounded-full object-cover mr-6 border-4 border-lime-500"
                            />
                        ) : (
                            <div className="w-20 h-20 rounded-full bg-lime-100 flex items-center justify-center mr-6 border-4 border-lime-500">
                                <span className="text-3xl text-lime-600 font-bold">{associateData.name ? associateData.name.charAt(0) : 'A'}</span>
                            </div>
                        )}
                        <div>
                            <h4 className="text-2xl font-bold text-gray-800">{associateData.name || 'N/A'}</h4>
                            <p className="text-gray-600">{associateData.email || 'N/A'}</p>
                        </div>
                    </div>
                    <div className="grid grid-cols-1 md:grid-cols-2 gap-6 mt-4">
                        <div className="flex items-center">
                            <Phone className="text-lime-600 mr-3" size={24} />
                            <div>
                                <p className="font-semibold text-gray-700">{associateData.mobile || 'N/A'}</p>
                                <p className="text-sm text-gray-500">Phone</p>
                            </div>
                        </div>
                        <div className="flex items-center">
                            <MapPin className="text-lime-600 mr-3" size={24} />
                            <div>
                                <p className="font-semibold text-gray-700">
                                    {[associateData.address, associateData.city, associateData.state].filter(Boolean).join(', ') || 'N/A'}
                                </p>
                                <p className="text-sm text-gray-500">Location</p>
                            </div>
                        </div>
                    </div>
                </div>

                {/* Search and Filter Section */}
                <div className="mb-8">
                    <div className="grid grid-cols-1 md:grid-cols-2 gap-4 mb-4">
                        <div className="relative">
                            <Search className="absolute top-3 left-3 text-gray-400" size={20} />
                            <input
                                type="text"
                                placeholder="Search by vehicle number"
                                value={vehicleNumber}
                                onChange={(e) => setVehicleNumber(e.target.value)}
                                className="w-full pl-10 pr-3 py-2 rounded-lg border-2 border-gray-200 outline-none focus:border-lime-500 transition duration-300"
                            />
                        </div>
                        <div className="relative">
                            <Phone className="absolute top-3 left-3 text-gray-400" size={20} />
                            <input
                                type="tel"
                                placeholder="Search by mobile number"
                                value={mobileNumber}
                                onChange={(e) => setMobileNumber(e.target.value)}
                                className="w-full pl-10 pr-3 py-2 rounded-lg border-2 border-gray-200 outline-none focus:border-lime-500 transition duration-300"
                            />
                        </div>
                    </div>
                    <motion.button
                        onClick={() => setShowFilters(!showFilters)}
                        className="flex items-center text-lime-600 hover:text-lime-800 font-semibold"
                        whileHover={{ scale: 1.05 }}
                        whileTap={{ scale: 0.95 }}
                    >
                        <Filter size={20} className="mr-2" />
                        {showFilters ? 'Hide Filters' : 'Show Filters'}
                        {showFilters ? <ChevronUp size={20} className="ml-2" /> : <ChevronDown size={20} className="ml-2" />}
                    </motion.button>
                </div>

                <AnimatePresence>
                    {showFilters && (
                        <motion.div
                            className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-4 mb-8"
                            initial={{ opacity: 0, height: 0 }}
                            animate={{ opacity: 1, height: 'auto' }}
                            exit={{ opacity: 0, height: 0 }}
                        >
                            <div className="relative">
                                <Calendar className="absolute top-3 left-3 text-gray-400" size={20} />
                                <input
                                    type="date"
                                    placeholder="From Date"
                                    value={dateFrom}
                                    onChange={(e) => setDateFrom(e.target.value)}
                                    className="w-full pl-10 pr-3 py-2 rounded-lg border-2 border-gray-200 outline-none focus:border-lime-500 transition duration-300"
                                />
                            </div>
                            <div className="relative">
                                <Calendar className="absolute top-3 left-3 text-gray-400" size={20} />
                                <input
                                    type="date"
                                    placeholder="To Date"
                                    value={dateTo}
                                    onChange={(e) => setDateTo(e.target.value)}
                                    className="w-full pl-10 pr-3 py-2 rounded-lg border-2 border-gray-200 outline-none focus:border-lime-500 transition duration-300"
                                />
                            </div>
                            <div className="relative">
                                <Clock className="absolute top-3 left-3 text-gray-400" size={20} />
                                <select
                                    value={status}
                                    onChange={(e) => setStatus(e.target.value)}
                                    className="w-full pl-10 pr-3 py-2 rounded-lg border-2 border-gray-200 outline-none focus:border-lime-500 transition duration-300 appearance-none"
                                >
                                    <option value="">All Statuses</option>
                                    <option value="completed">Completed</option>
                                    <option value="in progress">In Progress</option>
                                    <option value="draft">Draft</option>
                                </select>
                            </div>
                            <div className="relative">
                                <Search className="absolute top-3 left-3 text-gray-400" size={20} />
                                <input
                                    type="text"
                                    placeholder="Search client or vehicle"
                                    value={search}
                                    onChange={(e) => setSearch(e.target.value)}
                                    className="w-full pl-10 pr-3 py-2 rounded-lg border-2 border-gray-200 outline-none focus:border-lime-500 transition duration-300"
                                />
                            </div>
                        </motion.div>
                    )}
                </AnimatePresence>

                {/* Reports Section */}
                <div className="bg-white rounded-lg shadow-md overflow-hidden">
                    <div className="px-6 py-4 bg-gray-50 border-b border-gray-200">
                        <h4 className="text-xl font-semibold text-gray-800">Reports</h4>
                    </div>
                    <div className="divide-y divide-gray-200">
                        <AnimatePresence>
                            {filteredReports.length > 0 ? (
                                filteredReports.map((report) => (
                                    <motion.div
                                        key={report.id}
                                        className="flex items-center justify-between p-6 hover:bg-gray-50 transition duration-300"
                                        initial={{ opacity: 0, y: -20 }}
                                        animate={{ opacity: 1, y: 0 }}
                                        exit={{ opacity: 0, y: -20 }}
                                        transition={{ duration: 0.3 }}
                                    >
                                        <div className="flex items-start">
                                            <FileText className="text-lime-600 mr-4" size={32} />
                                            <div>
                                                <h5 className="font-semibold text-lg text-gray-800">{report.client_name}</h5>
                                                <p className="text-sm text-gray-600">Vehicle: {report.vehicle_number}</p>
                                                <p className="text-sm text-gray-600">Date: {new Date(report.createdAt).toLocaleDateString()}</p>
                                                <span className={`inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium mt-2 ${report.status.toLowerCase() === 'completed' ? 'bg-green-100 text-green-800' :
                                                    report.status.toLowerCase() === 'in progress' ? 'bg-yellow-100 text-yellow-800' :
                                                        'bg-gray-100 text-gray-800'
                                                    }`}>
                                                    <Clock className="mr-1" size={12} />
                                                    {report.status}
                                                </span>
                                            </div>
                                        </div>
                                        {report.status.toLowerCase() === 'completed' && (
                                            <motion.button
                                                className="flex items-center text-lime-600 hover:text-lime-800 font-semibold"
                                                whileHover={{ scale: 1.05 }}
                                                whileTap={{ scale: 0.95 }}
                                                onClick={() => generatePDF(report)}
                                                disabled={isGeneratingPDF}
                                            >
                                                {isGeneratingPDF ? (
                                                    <Loader />
                                                ) : (
                                                    <>
                                                        <Download className="mr-2" size={20} />
                                                        Download PDF
                                                    </>
                                                )}
                                            </motion.button>
                                        )}
                                    </motion.div>
                                ))
                            ) : (
                                <NoReportsFound onRefresh={handleRefresh} />
                            )}
                        </AnimatePresence>
                    </div>
                </div>
            </div>
            {/* Overlay Loader for PDF generation */}
            {isGeneratingPDF && (
                <div className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center z-50">
                    <Loader />
                </div>
            )}
        </motion.div>
    )
}